<template>
  <div>
    <Menu :menu="accessMenu" />
    <transition name="page">
      <router-view />
    </transition>
  </div>
</template>

<script>
import Menu from '@/components/atoms/Menu.vue'
import { ADDSELLERS_ADMIN_ROUTES } from '@/constants/routing'
import { mapGetters } from 'vuex'

export default {
  components: {
    Menu
  },
  data() {
    return {
      menu: [
        {
          title: 'SEO-FAQ',
          link: ADDSELLERS_ADMIN_ROUTES.CONTENT.SEOFAQS.LIST
        },
        {
          title: 'Страницы',
          link: ADDSELLERS_ADMIN_ROUTES.CONTENT.PAGES.LIST.INDEX
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      filterAccessMenuList: 'filterAccessMenuList'
    }),
    accessMenu() {
      return this.filterAccessMenuList(this.menu)
    }
  }
}
</script>
